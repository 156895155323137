import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import AdministrationForm from '../components/AdministrationForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';

const Admin: PageComponent = props => {
  const routeTo = (route: string) => () => navigate(route);
  const { user } = props;

  return (
    <Page {...props}>
      <FormWrapper>
        <AdministrationForm
          onChangeCigPricesClick={routeTo('/change-cig-prices')}
          onNewLottoGameClick={routeTo('/create-lotto-game')}
          onAuditErrorCheckClick={routeTo('/audit-error-check')}
          onReturnClick={routeTo('/')}
          user={user}
        />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(Admin, [1, 3]))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)`
  width: 800px;
`;
