import React from 'react';
import styled from 'styled-components';
import Row from './Row';

const BadBrowserBanner: React.FC = props => {
  return (
    <Row {...props}>
      This browser is not supported. Please open in Google Chrome.
    </Row>
  );
};

export default styled(BadBrowserBanner)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  z-index: 10;
`;
