import React, { FC, useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import BlockButton from './BlockButton';
import Input from './Input';
import Form from './Form';
import InputRow from './InputRow';
import FormBox from './FormBox';
import Row from './Row';
import Select from './Select';
import {
  LOGIN_DEFAULT_STORE_ID,
  LOGIN_DEFAULT_EMPLOYEE_ID,
  LOGIN_DEFAULT_PASSWORD,
  APP_STAGING,
} from '../env';

export type LoginFormOnSubmit = (result: LoginFormResult) => any;
export interface LoginFormResult {
  storeId: number;
  employeeId: number;
  password: string;
}

export interface LoginFormProps {
  onSubmit?: LoginFormOnSubmit;
  stores: StoreListItem[];
  storeId: string | null;
}

interface StoreListItem {
  storeId: number;
  storeName: string | null;
}

const LoginForm: FC<LoginFormProps> = props => {
  const { onSubmit, stores, ...rest } = props;
  const [storeId, setStoreId] = useState(
    props.storeId || LOGIN_DEFAULT_STORE_ID,
  );
  const [employeeId, setEmployeeId] = useState(LOGIN_DEFAULT_EMPLOYEE_ID);
  const [password, setPassword] = useState(LOGIN_DEFAULT_PASSWORD);
  const canSubmit =
    storeId.length > 0 && employeeId.length > 0 && password.length > 0;

  function handleEmployeeIdChange(event: ChangeEvent<HTMLInputElement>) {
    setEmployeeId(event.currentTarget.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.currentTarget.value);
  }

  function handleSubmit() {
    if (onSubmit && canSubmit) {
      onSubmit({
        employeeId: parseInt(employeeId, 10),
        storeId: parseInt(storeId, 10),
        password,
      });
    }
  }

  return (
    <FormBox title="LOGON: Form" {...rest}>
      <HeaderRow>
        <h3>{APP_STAGING ? 'DEV ' : ''}SHIFT LEDGER LOGON</h3>
      </HeaderRow>
      <Form onSubmit={handleSubmit} data-testid="form">
        {!props.storeId && (
          <InputRow>
            <label>Store</label>
            <Select
              value={storeId}
              onValueChange={setStoreId}
              data-testid="storeIdSelect"
            >
              <option value="" disabled>
                Select your store
              </option>
              {stores.map(store => (
                <option key={store.storeId} value={store.storeId}>
                  {store.storeName || 'Unnamed Store'}
                </option>
              ))}
            </Select>
          </InputRow>
        )}
        <InputRow>
          <label>Employee ID:</label>
          <Input
            type="number"
            value={employeeId}
            onChange={handleEmployeeIdChange}
            data-testid="employeeIdInput"
          />
        </InputRow>
        <InputRow>
          <label>Password:</label>
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            data-testid="passwordInput"
          />
        </InputRow>
        <BlockButton data-testid="submit-button" disabled={!canSubmit}>
          Enter
        </BlockButton>
      </Form>
    </FormBox>
  );
};

export default styled(LoginForm)`
  justify-content: center;
  align-items: center;
  flex: 1;

  h3 {
    text-align: center;
  }

  ${Form} {
    flex: 2;
    align-items: center;
    max-width: 100%;
  }

  label {
    width: 120px;
  }

  input,
  select {
    height: 25px;
    width: 140px;
    padding: 0;
  }

  ${BlockButton} {
    margin-top: 20px;
    height: 30px;
    min-height: 30px;
    width: 60px;
    min-width: 60px;
    padding-top: 2px;
  }

  width: 300px;
  height: 245px;
  max-height: 245px;
`;

const HeaderRow = styled(Row)`
  flex: 1;
`;
