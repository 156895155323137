import { FC } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import User from '../models/User';
import { APP_STAGING } from '../env';

const Page = styled(Column)`
  min-height: 100vh;
  ${APP_STAGING && 'background-color: #1f497d'}
`;

export interface PageProps {
  path: string;
  default?: boolean;
  user?: User;
}

export type PageComponent<P = {}> = FC<PageProps & P>;

export default Page;
