import { AxiosInstance } from 'axios';
import Employees from '../models/Employees';

export async function findEmployee(
  axios: AxiosInstance,
  employeeId: string,
  name: string,
): Promise<Employees | null> {
  const response = await axios.post('/employee/find', {
    employeeId,
    name,
  });
  return response.data.employee;
}
